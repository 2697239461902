<template>
  <div>
    <Header />
    <Logo msg="维护所属公司" />
    <Loading v-if="isLoading" />
    <div class="conter">
      <div class="root">
        <el-form
          :model="Register"
          ref="RegisterForm"
          label-width="0"
          class="RegisterForm"
        >
        <el-form-item prop="phone">
            <el-input
              v-model="Register.phone"
              placeholder="请输入手机号"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item prop="companyName">
            <el-input
              v-model="Register.companyName"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Foot />
    <el-button :plain="true" v-show="false"></el-button>
  </div>
</template>
<script>
import { setCookie, getCookie, delCookie } from '@/utils/cookie';
import { post, get } from '@/utils/request';

import Header from '@/components/header.vue';
import Baoyou from '@/components/order/baoyou1.vue';
import Foot from '@/components/foot.vue';
import Logo from '@/components/order/safe/logo.vue';
export default {
  components: {
    Header,
    Baoyou,
    Foot,
    Logo,
  },
  data() {
    return {
      Register: {
        companyName: '',
        phone: '',
      },
      input3: '',
      select: '',
      myForm: {},
      isLoading: false,
    };
  },
  created() {
    this.getMemberInfo();
  },
  methods: {
    // 获取当前登录会员信息
    getMemberInfo() {
      get('api/member/selMemberInfo').then((res) => {
        this.isLoading = false;
        this.Register.companyName=res.data.data.companyName;
        this.Register.phone=res.data.data.phone;
      });
    },
    submitForm() {
      this.isLoading = true;
      if (
        this.Register.companyName 
      ) {
          post('api/member/updateCompanyName', {
            companyName: this.Register.companyName,
          }).then((res) => {
            this.isLoading = false;
            if (res.data.msg == '操作成功') {
              this.$message.success({
                message: '修改成功',
              });
              setCookie('companyName', res.data.data.companyName, 7200);
              this.$router.push({
                name: 'Home',
              });
            } else {
              this.$message.error({
                message: '修改失败',
              });
            }
          });
      } else {
        this.isLoading = false;
        this.$message.warning({
          message: '请填写完整所属公司',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.conter {
  width: 100%;
  margin-top: 149px;
  .root {
    background: url('http://img.hena360.cn/login_bj.jpg') no-repeat center;
    background-size: 100% 100%;
    height: 10px;
    width: 440px;
    margin: auto;

    form {
      width: 90%;
      margin: auto;
      .code {
        .el-input {
          width: 65%;
          border-radius: 0px;
          float: left;
          display: inline-block;
        }
        .el-button {
          width: 35%;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px;
          float: left;
          display: inline-block;
        }
      }
      .el-button--success {
        width: 100%;
        background-color: #245af2;
        border: none;
        color: #fff;
      }
    }
  }
}

.form-wrapper {
  margin-top: 50px;
  width: 500px;
  margin-left: 0;
}
.conter {
  padding-bottom: 333px;
  width: 100%;
  .buzhou {
    width: 612px;
    margin: auto;
    margin-top: 50px;
    .logo-search-progress {
      width: 100%;
      height: 104px;
    }
  }
  .input {
    width: 612px;
    height: 100%;
    margin: auto;
    margin-top: 90px;
    display: flex;
    .el-select {
      width: 250px;
    }
  }
  .yanzheng {
    width: 612px;
    margin: auto;
    text-align: center;
    cursor: pointer;
  }
}
</style>
